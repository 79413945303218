import React from 'react'
import * as styles from './index.module.css'

export const Modal = ({showState=[false, ()=>{}], children}) => {
    const [showModal, setShowModal] = showState
    
    return (
        <div>
            {showModal && 
            (
            <>
            <div className={styles.modal_container} onClick={()=>setShowModal(!showModal)}></div>
            <div className={styles.modal_item}>
            {children}
            </div>
            </>
            )}
        </div>
    )
}
