import React from 'react'
import * as styles from './index.module.css'

import { Layout } from '../../components/layout'
import { Modal } from '../../components/modal';
import { Swipe } from '../../components/swipe';

import {useGalleryData} from "../../hooks/gallery"
import { CONTEN_TYPE_FIELDS } from '../../constains';

export default function Index({params}) {
    const showModalState = React.useState(false);
    const pickIndexPhoto = React.useState(0);

    const data  = useGalleryData();
    const gallery_data = mapGalleryData(data, params.index)

    const handleImgClick = (key=0) => {
        showModalState[1](!showModalState[0])
        pickIndexPhoto[1](key)
    }

    return (
        <Layout>
            <div className={styles.gallery_container}>
                {gallery_data.map((photo, index) => <img key={index} src={photo.url} alt={photo.title || `tsampo_tatto_gallery_photos`} onClick={() => handleImgClick(index)}/>)}
            </div>

            <Modal
                showState = {showModalState}
            >
                <div className={styles.gallery_swipper}>
                    <Swipe photo_list={gallery_data} initialSlide={pickIndexPhoto[0]}/>
                </div>
            </Modal>
        </Layout>
    )
}

function mapGalleryData(data, album) {
    return (data[album] || data[CONTEN_TYPE_FIELDS.DEFAULT]).map(item => ({url: item.file.url, title: item.title}))
}