import React from 'react'
import {Link} from "gatsby"
import * as styles from "./index.module.css"
import faceIcon from "../../images/facebook.png"
import instagramIcon from "../../images/instagram.png"

import { SOCIAL_MEDIA } from '../../constains' 

export const Social = ({socialIcon = SOCIAL_MEDIA, action = () => {}, ...props}) => {
    return (
        <div className={styles.social_container} {...props}>
            {socialIcon.map(icon => <Link to={icon.linkTo}><img src={icon.icon} onClick={action} alt={`tsampos_tattoo_social_icon_${icon.type}`}/></Link>)}
        </div>
    )
}
