import React from 'react'
import * as styles from "./index.module.css"

export const BurgerMenu = ({isClicked=false}) => {
    return (
        <>
            <input type="checkbox" checked={isClicked} className={styles.menu_checkbox_id}/>
            <label>
                <div></div>
                <div></div>  
                <div></div>  
            </label>
        </>
    )
}
