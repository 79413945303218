import * as React from "react"
import { Sidebar } from "../navigation/sidebar"
import { Footer } from "../footer"

// markup
export const Layout = ({children}) => {
  return (
    <div>

      {/* NAVIGATION */}
      <Sidebar />

      {children}

      {/* FOOTER */}
      <Footer />

    </div>
  )
}