import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

export const useGalleryData = () => {
        const data = useStaticQuery(graphql`
        query galleryTattoo {
            allContentfulTattooGallery {
              nodes {
                myPrints {
                  title
                  file {
                    url
                  }
                }
                allAround {
                  file {
                    url
                  }
                  title
                }
                myTattoo {
                  title
                  file {
                    url
                  }
                }
              }
            }
          }
        `)
    return data.allContentfulTattooGallery.nodes[0]
}
