import React from 'react'
import * as styles from "./index.module.css"
import { Social } from '../social'

export const Footer = () => {
    return (
        <div className={styles.footer_container}>
            <Social />
        </div>
    )
}
