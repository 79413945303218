import { Children } from "react"
import faceIcon from "../images/facebook.png"
import instagramIcon from "../images/instagram.png"

export const CONTEN_TYPE_FIELDS = {
    MY_TATTOO: "myTattoo",
    ALL_AROUND: "allAround",
    MY_PRINTS: "myPrints",
    DEFAULT: "myTattoo",
}

export const GALLERY_TITLE = {
    MY_TATTOO: 'My Tattoo',
    ALL_AROUND: 'All Around',
    MY_PRINTS: 'My Prints'
}

export const NAVIGATION_MAP = [
    {
        text: "About",
        linkTo: "/#about"
    },
    {
        text: "Gallery",
        linkTo: "#",
        children: [
                {
                    text: GALLERY_TITLE.MY_TATTOO,
                    linkTo: `/gallery/${CONTEN_TYPE_FIELDS.MY_TATTOO}`,
                },
                {
                    text: GALLERY_TITLE.ALL_AROUND,
                    linkTo: `/gallery/${CONTEN_TYPE_FIELDS.ALL_AROUND}`
                },
                {
                    text: GALLERY_TITLE.MY_PRINTS,
                    linkTo: `/gallery/${CONTEN_TYPE_FIELDS.MY_PRINTS}`
                }
        ]
    }
]

export const SOCIAL_MEDIA = [
    {
        type: "instagram",
        icon: instagramIcon,
        linkTo: "https://www.instagram.com/tsamp_os/"
    },
    {
        type: "facebook",
        icon: faceIcon,
        linkTo: "https://m.facebook.com/profile.php?id=115604863592091&ref=content_filter"
    }
]