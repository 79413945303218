import React from 'react'

import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import * as styles from './index.module.css'

export const Swipe = ({photo_list = [{url:"", title:""}], ...swiperProps}) => {
    
    return (
        <>
            <Swiper
                modules={[Navigation]}
                navigation
                {...swiperProps}
            >
                {photo_list.map(photo => <SwiperSlide><img width="100%" src={photo.url} alt={photo.title || `tsampo_tatto_gallery_photos`}/></SwiperSlide>)}
            </Swiper>
        </>
    )
}
