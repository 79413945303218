import { Link } from 'gatsby'
import React from 'react'
import { NAVIGATION_MAP } from '../../../constains'
import { Social } from '../../social'
import { BurgerMenu } from '../burgermenu'
import * as styles from "./index.module.css"

export const Sidebar = () => {
    const [openSideBar, setOpenSideBar] = React.useState(false)

    return (
        <div className={styles.sidebar}>
            <div className={openSideBar ? styles.sidebar_menu_show : styles.sidebar_menu}>
            
                <div className={openSideBar ? styles.burger_menu_show : styles.burger_menu} onClick={() => setOpenSideBar(!openSideBar)}>
                    <BurgerMenu isClicked={openSideBar}/>
                </div>

                <div className={styles.menu}>
                    <CreateSideMenu sideMenuData={NAVIGATION_MAP} onClosedSideBar={() => setOpenSideBar(!openSideBar)}/>
                </div>

            </div>

            <div className={styles.social_media_container}>
                <Social />
            </div>
        </div>
    )
}


const CreateSideMenu = ({sideMenuData=[], onClosedSideBar}) => {
    
    const handleSideMenuData = (sideMenu) => {
        return (sideMenu.reduce((acc, cur) => {
            if (cur.children) {
                acc.push(<>
                    <SidebarItems cur={cur} fn={handleSideMenuData}/>
                </>)
                return acc
            }
            acc.push(<Link to={cur.linkTo} onClick={onClosedSideBar}> {cur.text} </Link>)
            return acc
        }, []))
    }

    return (
    <div className={styles.menu_items}>
        {handleSideMenuData(sideMenuData)}
    </div>
    )
}


const SidebarItems = ({cur, fn}) => {
    const showItems = React.useState(false)
    return (
        <>
            <Link to={cur.linkTo} onClick={()=> showItems[1](!showItems[0])}> {cur.text} </Link>
            {showItems[0] && <div className={`animate__animated animate__fadeIn ${styles.menu_gallery_items}`}>
                {fn(cur.children)}
            </div>}
        </>
    )
}